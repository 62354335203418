import React from "react";
import classNames from "classnames";
import { Link, NavLink } from "react-router-dom";
import { Container } from "../Grid/Grid";
import { Svg, SVG_ICONS } from "../__common/Svg/Svg";
import { Typography } from "../__common/Typography/Typography";
import { Tooltip } from "../__common/Tooltip/Tooltip";
import { HeaderMenu } from "../../constants/nav";

import "./Drawer.scss";
import { useEffect, useState } from "react";
import { Image } from "../__common/Image/Image";
import { LinkButton } from "../__common/_controls";
import { useUserHooks } from "../../hooks/user-hooks";
import { API_ENDPOINTS, API_SA_BASE_URL } from "../../constants/api";
import { LOGIN_ID_TYPE } from "../../types/user";

import { Trans, useTranslation } from "react-i18next";
import triggerGAEvent from "../../utils/UtilsTriggerGAEvent";
import { EVENT_CATEGORY_HEADER } from "../../constants/analytics";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../store";
import { LayoutState } from "../../store/reducers/layout-reducer";
import LocalPrivacySiteModal from "../../pages/nonauth/LandingPage/LocalPrivacySiteModal/LocalPrivacySiteModal";
import { COUNTRY_FLAGS } from "../__common/Image/flagImages";
import { useHub } from "../../hooks/hub-hooks";
import { UtilsUser } from "../../utils/UtilsUser";
import { useLoginOptionHooks } from "../../hooks/login-option-hooks";
import {
  EMAIL_LOGIN_VERIFICATION_RESULT,
  REDIRECT,
} from "../../constants/storage";
import FocusTrapping from "../__hoc/FocusTrapping";
import { LocalStorage } from "../../services/storage";
import { ROUTES } from "../../configs/routes";

interface Props {
  isDrawerOpen: boolean;
  isLogin?: boolean;
  onClickDrawerClose: () => void;
  onClickLogin: () => void;
  onClickSignOut: () => void;
  onClickLoginTestId?: string;
}

export const Drawer: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { userData } = useUserHooks();
  const {
    isDrawerOpen,
    onClickDrawerClose,
    isLogin,
    onClickLogin,
    onClickSignOut,
    onClickLoginTestId,
  } = props;
  const [activeTooltip, setActiveTooltip] = useState<string | number>("");
  const wrapClass = classNames("menu-drawer", { open: !!isDrawerOpen });
  const { handleLoginBasedOnGeoIp } = useLoginOptionHooks();
  const [isCountryListModalOpen, setIsCountryListModalOpen] = useState(false);
  const { selectedCountry, isHighContrast } = useSelector<
    ApplicationState,
    LayoutState
  >((state) => state.layout);

  const emailLoginVerificationResult = LocalStorage.get(
    EMAIL_LOGIN_VERIFICATION_RESULT
  );

  const {
    hasLocalSite,
    isCountryNotAllowed,
    handleLocalSiteRedirect,
    handleGAMyDataRedirectNav,
  } = useHub();

  const handleOnClickDrawerClose = () => {
    handleOnClickTooltipClose();
    onClickDrawerClose();
  };

  const handleMenuClick = (gaAction: string, id?: number) => {
    if (id === 2) {
      handleGAMyDataRedirectNav();
      if (!UtilsUser.isUserLoggedIn()) {
        sessionStorage.setItem(REDIRECT, ROUTES.MYDATA);
        handleLoginBasedOnGeoIp();
      }
    } else {
      triggerGAEvent(EVENT_CATEGORY_HEADER, gaAction);
    }
    onClickDrawerClose();
  };

  const handleOnClickTooltip = (id: string | number) => {
    setActiveTooltip(id);
  };

  const handleOnClickTooltipClose = () => {
    setActiveTooltip("");
  };

  useEffect(() => {
    const body = document.body;

    if (isDrawerOpen) {
      body.classList.add("modal-open");
    } else {
      body.classList.remove("modal-open");
    }
  }, [isDrawerOpen]);

  return (
    <>
      <div className={wrapClass}>
        <div className="menu-drawer__close">
          <div
            data-testid="on-click-drawer-close"
            className="menu-drawer__close__btn"
            onClick={handleOnClickDrawerClose}
          >
            <Svg icon={SVG_ICONS.DRAWER_CLOSE} width={24} height={24} />
          </div>
        </div>
        <Container>
          <div className="drawer__profile">
            <div className="menu-drawer__profile__icon">
              {isLogin ? (
                userData.profileImageUrl ? (
                  <Link
                    target="_blank"
                    to={{
                      pathname: `${API_SA_BASE_URL}${API_ENDPOINTS.SA_PROFILE}`,
                    }}
                    className={`name-and-icon-wrapper ${
                      emailLoginVerificationResult === "true"
                        ? "block-events"
                        : ""
                    }`}
                  >
                    <Image
                      testId={onClickLoginTestId}
                      image={userData.profileImageUrl}
                      name={userData.name}
                      width={48}
                      height={48}
                      onClick={onClickLogin}
                    />
                  </Link>
                ) : (
                  <Link
                    target="_blank"
                    to={{
                      pathname: `${API_SA_BASE_URL}${API_ENDPOINTS.SA_PROFILE}`,
                    }}
                    className={`name-and-icon-wrapper ${
                      emailLoginVerificationResult === "true"
                        ? "block-events"
                        : ""
                    }`}
                  >
                    <Svg
                      testId="profile-placeholder"
                      icon={SVG_ICONS.PROFILE}
                      className="profile-placeholder"
                      width={48}
                      height={48}
                    />
                  </Link>
                )
              ) : (
                <Svg
                  icon={SVG_ICONS.PROFILE_SIGNOUT_CIRCLE}
                  width={48}
                  height={48}
                />
              )}
            </div>
            <div className="menu-drawer__profile__text__container">
              <span
                data-testid="login-button"
                className={`${
                  emailLoginVerificationResult === "true" ? "block-events" : ""
                }`}
                onClick={onClickLogin}
              >
                <Typography
                  className="menu-drawer__profile__text"
                  variant="title3"
                >
                  {emailLoginVerificationResult === "true" ? (
                    <>{userData.name}</>
                  ) : isLogin ? (
                    <Link
                      target="_blank"
                      to={{
                        pathname: `${API_SA_BASE_URL}${API_ENDPOINTS.SA_PROFILE}`,
                      }}
                      className={`name-and-icon-wrapper sa-name-wrapper${
                        emailLoginVerificationResult === "true"
                          ? "block-events"
                          : ""
                      }`}
                    >
                      {userData.name}
                    </Link>
                  ) : (
                    t("PRIVACY_HEADER_SIGNIN")
                  )}
                </Typography>
              </span>
            </div>

            {isLogin && (
              <div onClick={onClickSignOut}>
                <span className="menu-drawer__signout-button">
                  {t("PRIVACY_HEADER_SIGNOUT")}
                </span>
              </div>
            )}
          </div>

          <div
            className={classNames("menu-drawer__message_container", {
              login: !!isLogin,
            })}
          >
            <Typography
              className="menu-drawer__message"
              variant="title1"
              weight="w400"
            >
              {t("PRIVACY_HEADER_USER_POPUP_GREETING")} <br />
              {t("PRIVACY_HEADER_USER_POPUP_GREETING_TEXT")}
            </Typography>
          </div>
          {isLogin && (
            <div
              className={`menu-drawer__email ${
                emailLoginVerificationResult === "true" ? "block-events" : ""
              }`}
            >
              <LinkButton
                title={
                  userData.loginIdType === LOGIN_ID_TYPE.EMAIL
                    ? userData.validatedEmail
                    : userData.loginId
                }
                to={{
                  pathname: `${API_SA_BASE_URL}${API_ENDPOINTS.SA_PROFILE}`,
                }}
                target="_blank"
                underlined={false}
                icon={!UtilsUser.isEmailLogin() && SVG_ICONS.GREATE_THAN_SML}
                textClassName="menu-drawer__email__text"
              />
            </div>
          )}
          <div className="horizontal-divider" />
          <div className="menu-drawer__navs">
            <ul>
              {HeaderMenu.map((menu) => (
                <li className="menu-drawer__navs__item" key={menu.id}>
                  <div className="nav-item">
                    <div className="item-icon">
                      {menu.icon && (
                        <Svg icon={menu.icon} width={22.9} height={18.4} />
                      )}
                    </div>
                    <div className="item-text">
                      {menu.title.link !== "" ? (
                        menu.id === 2 && hasLocalSite ? (
                          <Typography
                            testId="local-site-redirect-button"
                            onClick={() => {
                              handleGAMyDataRedirectNav();
                              handleLocalSiteRedirect();
                            }}
                            variant="title2"
                          >
                            {t(menu.title.text)}
                          </Typography>
                        ) : (
                          <Link
                            to={
                              UtilsUser.isUserLoggedIn() ? menu.title.link : "#"
                            }
                            data-testid="menu-with-id-2"
                            onClick={() => handleMenuClick(menu.gaAction, 2)}
                          >
                            {t(menu.title.text)}
                          </Link>
                        )
                      ) : (
                        <Typography variant="title2">
                          {t(menu.title.text)}
                        </Typography>
                      )}
                    </div>
                    {menu.items && (
                      <div
                        className="item-tooltip"
                        data-testid="on-click-tooltip"
                        onClick={() => handleOnClickTooltip(menu.id)}
                      >
                        <Svg
                          icon={SVG_ICONS.TOOLTIP_OUTLINE}
                          width={24}
                          height={24}
                        />
                      </div>
                    )}
                  </div>
                  {activeTooltip === menu.id && menu.items && (
                    <Tooltip
                      className="menu-item-tooltip"
                      onClickClose={handleOnClickTooltipClose}
                    >
                      {menu.items.map((item) => (
                        <>
                          <Typography className="tooltip-title" variant="body3">
                            {t(item.text)}
                          </Typography>
                          <Typography
                            className="tooltip-sub-title"
                            variant="body3"
                          >
                            {item.detail ? t(item.detail.text) : ""}
                          </Typography>
                        </>
                      ))}
                    </Tooltip>
                  )}
                  <ul className="sub-items">
                    {menu.showLocation ? (
                      <>
                        <li className="sub-item-link">
                          <div>
                            <Typography
                              variant="title3"
                              weight="w400"
                              onClick={handleLocalSiteRedirect}
                            >
                              {t("PRIVACY_HUB_MOBILE_DRAWER_MANAGEDATA")}
                            </Typography>
                          </div>
                          <div className="sub-item-location">
                            {isHighContrast ? (
                              <Svg
                                icon={SVG_ICONS.MYDATA_LOCATION_ARROW_HC}
                                width={12}
                                height={12}
                              />
                            ) : (
                              <Svg
                                icon={SVG_ICONS.MYDATA_LOCATION_ARROW}
                                width={12}
                                height={12}
                              />
                            )}
                            <div>
                              <Typography
                                variant="title3"
                                weight="w400"
                                onClick={handleLocalSiteRedirect}
                              >
                                <Trans
                                  i18nKey="PRIVACY_HUB_MOBILE_DRAWER_MYLOCATION"
                                  components={{
                                    span: <span></span>,
                                  }}
                                  values={{ "%s": selectedCountry.name }}
                                />
                                &nbsp;
                              </Typography>
                            </div>
                            {!isCountryNotAllowed && (
                              <Image
                                image={COUNTRY_FLAGS[selectedCountry.code]}
                                width={34}
                                height={18}
                              />
                            )}
                          </div>
                        </li>
                        <li className="sub-item-link">
                          <Typography
                            variant="title3"
                            testId="open-country-list-modal"
                            weight="w400"
                            onClick={() => {
                              setIsCountryListModalOpen(true);
                            }}
                          >
                            {t("PRIVACY_HUB_MOBILE_DRAWER_CHANGE_COUNTRY")}
                          </Typography>
                        </li>
                      </>
                    ) : null}
                    {menu.items &&
                      menu.items.map((item) => (
                        <li className="sub-item-link" key={item.id}>
                          <NavLink
                            data-testid="menu-nav-link"
                            to={item.link}
                            onClick={() => handleMenuClick(item.gaAction)}
                          >
                            {t(item.text)}
                          </NavLink>
                        </li>
                      ))}
                  </ul>
                </li>
              ))}
            </ul>
          </div>
        </Container>
        <FocusTrapping
          isActive={isCountryListModalOpen}
          elements="div.modal-body, div.title2, button, input"
        >
          <LocalPrivacySiteModal
            show={isCountryListModalOpen}
            onClickClose={() => {
              setIsCountryListModalOpen(false);
            }}
          />
        </FocusTrapping>
      </div>
    </>
  );
};
