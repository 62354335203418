// import axios from "axios";

import { ROUTES } from "../configs/routes";
import { API_PUBLIC_AUTH_TOKEN } from "../constants/api";
import { RCODES } from "../constants/rcodes";

const Http = {
  getHeaders: async (extraHeaders?: any) => {
    const defaultHeaders = {
      Pragma: "no-cache",
      "Cache-Control": "no-cache",
      "x-sc-useragent": ";;WebPortal=0.1;Windows NT 6.1; WOW64;",
      "Content-type": "application/json;charset=UTF-8",
      "x-sc-client-os-version": 27,
    };

    return extraHeaders
      ? { ...defaultHeaders, ...extraHeaders }
      : defaultHeaders;
  },

  get: async (url: string, extraHeaders?: any, isPublic?: false) => {
    const headers = await Http.getHeaders(extraHeaders);
    const response = await fetch(url, {
      method: "GET",
      headers: isPublic
        ? { Authorization: API_PUBLIC_AUTH_TOKEN }
        : { ...headers },
    });
    const responseData = await response.json();
    if (response.status > 399) {
      // function for error handling based on HTTP status
      handleAPIErrorBasedOnHTTPstatus(response.status);
      return Promise.reject(responseData);
    }
    if (responseData.rcode > 0) {
      handleAPIErrorBasedOnRcode(responseData.rcode);
      return Promise.reject(responseData);
    }

    return responseData;
  },

  post: async (
    url: string,
    data?: any,
    extraHeaders?: any,
    controller?: any
  ) => {
    try {
      const headers = await Http.getHeaders(extraHeaders);
      const response = await fetch(url, {
        method: "POST",
        headers,
        body: JSON.stringify(data),
        ...controller,
      });

      const responseData = await response.json();

      if (response.status > 399) {
        // function for error handling based on HTTP status
        handleAPIErrorBasedOnHTTPstatus(response.status);
        return Promise.reject(responseData);
      }
      if (responseData.rcode > 0) {
        handleAPIErrorBasedOnRcode(responseData.rcode);
        return Promise.reject(responseData);
      }

      return responseData;
    } catch (e: any) {
      console.error(e);
      return;
    }
  },
};

const handleAPIErrorBasedOnRcode = (rcode: number) => {
  switch (rcode) {
    // temporarily remove the error 401 handler since the /profile API is still being call after successful logout,
    // due to the delay removal of SC_ATKN
    // case RCODES.UNAUTHORIZED:
    //   window.location.href = ROUTES.LOGIN;
    //   break;
    case RCODES.BAD_REQUEST:
      window.location.href = ROUTES.ERROR_400;
      break;
    case RCODES.INTERNAL_SERVER_ERROR:
    case RCODES.DATABASE_ERROR:
    case RCODES.DATABASE_ERROR2:
      window.location.href = ROUTES.ERROR_500;
      break;
  }
};

const handleAPIErrorBasedOnHTTPstatus = (statusCode: number) => {
  switch (statusCode) {
    case 400:
      window.location.href = ROUTES.ERROR_400;
      break;
    // case 401:
    //   window.location.href = ROUTES.LOGIN;
    //   break;
    case 403:
      window.location.href = ROUTES.ERROR_403;
      break;
    case 404:
      window.location.href = ROUTES.ERROR_404;
      break;
    case 500:
      window.location.href = ROUTES.ERROR_500;
      break;
    case 502:
      window.location.href = ROUTES.ERROR_502;
      break;
    case 503:
      window.location.href = ROUTES.ERROR_503;
      break;
  }
};

export default Http;
