import { useContext } from "react";
import { FeatureToggleContext } from "./FeatureToggleContext";

// NOTE:
// Only `SURVEY` and `SITE_SETTINGS` are permanent.
// Update this enum to toggle additional features in the future.
export enum DISABLED_FEATURES {
  SURVEY = "survey",
  SITE_SETTINGS = "site_settings",
  DIGITAL_LEGACY = "digital_legacy",
}

export const useFeatureToggle = () => {
  const { disabledFeatures } = useContext(FeatureToggleContext);

  /** Check if a feature is disabled */
  const isDisabled = (featureName: DISABLED_FEATURES) => {
    return disabledFeatures.includes(featureName);
  };

  return { isDisabled };
};
